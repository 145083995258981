<template>
    <main class="bg-secondary bg-dark-gray">
        <div class="container py-5">
            <div class="row py-4">
                <div class="col-12 fl-y fl-a-c mb-4 mb-lg-0" data-aos="fade-up" data-aos-duration="1500">
                    <h5 class="text-danger lt-gp-2 mb-4 text-center">
                        WHY CHOOSE RED CARPET?
                    </h5>
                    <h2 class="text-xl-gl text-left mb-5 text-center">We listen. We design. We Create.</h2>
                </div>
                <transition name="fade" mode="out-in">
                    <div class="col-12 fl-y fl-a-c fl-j-c card-height" v-if="activeTab === 'tab-1'" key="'tab1">
                        <i class="fa fa-comment-o fa-4x mb-4 text-danger"></i>
                        <h2 class="mb-4">We are good listeners</h2>
                        <section class="row">
                            <p class="text-center">
                                Simply because our environment at home and at work have more impacts than it is
                                claimed on our health and personal fulfillment. The well-being and adaptation of
                                places of life and work allow my sense to be in harmony with our way of life,
                                sometimes very rhythmic and rough, to relax, to optimize our tasks, to develop
                                ourselves personally every day and so to evolve more serenely.
                            </p>
                        </section>
                    </div>
                    <div class="col-12 fl-y fl-a-c fl-j-c card-height" v-else-if="activeTab === 'tab-2'" key="'tab2">
                        <i class="fa fa-edit fa-4x mb-4 text-danger"></i>
                        <h2 class="mb-4">We are creative</h2>
                        <section class="row">
                            <p class="text-center">
                                Having a strong attraction for the harmony of the colors, the materials, the objects of
                                art and for the optimization of the arrangement of the space improving the everyday
                                life, it is quite naturally that we orient myself towards the council in interior design
                                and decoration. Our desire for creativity, challenge and improvement of well-being at
                                home and at work have led me to create my own company, Interior Projects, to put my
                                ideas and my skills at your service, that you be particular or professional. Our mission
                                now is to optimize your living or working environment to make your daily life easier;
                                and to harmonize and affirm the style of your space so that it is an extension of your
                                identity. From the simple provision
                            </p>
                        </section>
                    </div>
                    <div class="col-12 fl-y fl-a-c fl-j-c card-height" v-else key="'tab3">
                        <i class="fa fa-clock-o fa-4x mb-4 text-danger"></i>
                        <h2 class="mb-4">We deliver on time</h2>
                        <section class="row">
                            <p class="text-center">
                                From the simple provision of home advice to the implementation of decoration, through
                                the design of plans and the renovation of your home, Red carpet offers personalized
                                support. We made the decision to compose our team of decorators and interior designers
                                to offer a wider range of services. The franchise system allows our agencies to be
                                accompanied and monitored in their development. The decorators and interior designers of
                                these agencies are therefore regularly trained but are also required to work according
                                to the same methods, with the same software, etc. This ensures our clients quality and
                                consistency of service, regardless of the agency that will operate in their homes.
                            </p>
                        </section>
                    </div>
                </transition>
                <div class="col-12 lg-fl-x-sm-y">
                    <div class="mr-lg-5 mb-lg-0 mb-2 tabBtn" :class="{active:activeTab === 'tab-1'}"
                         @click="switchTab('tab-1')">WE ARE GOOD LISTENER
                    </div>
                    <div class="mr-lg-5 tabBtn mb-lg-0 mb-2" :class="{active:activeTab === 'tab-2'}"
                         @click="switchTab('tab-2')">WE ARE CREATIVE
                    </div>
                    <div class="mr-lg-5 tabBtn mb-lg-0 mb-2" :class="{active:activeTab === 'tab-3'}"
                         @click="switchTab('tab-3')">WE DELIVER ON TIME
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    data () {
        return {
            activeTab: 'tab-1'
        };
    },
    methods: {
        switchTab (tab) {
            this.activeTab = tab;
        }
    }
};
</script>
<style lang="scss" scoped>

.card-height {
    min-height: 25rem !important;
}

</style>
