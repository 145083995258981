<template>
    <div class="bg-inner">
        <div class="overlay-dark"></div>
    </div>
</template>

<script>
export default {
    name: 'InnerBanner'
};
</script>

<style scoped>
.bg-inner{
    position: relative;
    background-image: url('../../assets/images/slide/2.jpg');
    min-height: 50vh;
    background-size: cover;
    background-position: 60%    ;
}
.overlay-dark {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.48);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 100px
}
</style>
