<template>

    <section>
        <div class="container">
<!--            <div class="row py-5 px-0 text-center">-->
<!--                <div class="col-12 fl-y fl-a-c" data-aos="fade-up" data-aos-duration="800">-->
<!--                    <h5 class="text-danger lt-gp-2">-->
<!--                        MEET THE TEAM-->
<!--                    </h5>-->
<!--                    <h2>People busy making your dream a reality.</h2>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="row p-0">-->
<!--                <div class="col- 12 col-lg-3 fl-x fl-j-c mb-5 mb-lg-0" data-aos="fade-up" data-aos-duration="1200">-->
<!--                    <base-card img="gal1.jpg"></base-card>-->
<!--                </div>-->
<!--                <div class="col- 12 col-lg-3 fl-x fl-j-c mb-5 mb-lg-0" data-aos="fade-up" data-aos-duration="1200">-->
<!--                    <base-card img="gal1.jpg"></base-card>-->
<!--                </div>-->
<!--                <div class="col- 12 col-lg-3 fl-x fl-j-c mb-5 mb-lg-0" data-aos="fade-up" data-aos-duration="1200">-->
<!--                    <base-card img="gal1.jpg"></base-card>-->
<!--                </div>-->
<!--                <div class="col- 12 col-lg-3 fl-x fl-j-c mb-5 mb-lg-0" data-aos="fade-up" data-aos-duration="1200">-->
<!--                    <base-card img="gal1.jpg"></base-card>-->
<!--                </div>-->
<!--            </div>-->
            <!--WE ARE DESIGNERS-->
            <div class="row p-0">
                <div class="col-12 py-5">
                    <h2 class="text-xxl">We are <span class="text-danger">Designers.</span></h2>
                    <div data-aos="fade-up" data-aos-duration="900">
                        <p>
                            We would like to introduce you to our projects in Red carpet and services on the following
                            pages. We realize the conversion and new construction of your hotel, modern and
                            professional. As
                            a competent partner, we have been supporting our customers for long time. Customer
                            satisfaction
                            is always our focus. As your interior design office together we create the ideal atmosphere
                            for
                            your guests. We are happy to talk to you or send you additional information. It's just fun
                            to
                            get to know people who are new to the new and sometimes can say goodbye to old principles
                            and to
                            see how initial skepticism turns into real enthusiasm. When my customers realize that their
                            needs and preferences are taken seriously and are surprised by solutions that seem as
                            unexpected
                            as they are obvious. And then say, "We should have done that much sooner." Finding creative
                            solutions that are not immediately obvious is my strength. With experience and empathy in
                            the
                            situation of the customer to realize what he really cares about, even between the lines to
                            read.
                        </p>
                        <ul class="pl-lg-5 pl-3 py-4">
                            <li class="mb-2">
                                We love to work with people in a trusting and constructive way, be it with customers or
                                craftsmen and when a plan works, even if it's Plan B.
                            </li>
                            <li class="mb-2">
                                What we do not like is arrogance, unreliability and crochet doilies.
                            </li>
                            <li class="mb-2">
                                One of our quirks is that there is always a kind of "room improvement program" going on
                                in
                                our head. Wherever we are, we scan the environment for optimization opportunities. But
                                we
                                are working on not being too concerned.
                            </li>
                        </ul>
                        <p>
                            Since the founding of the architecture office 2018 we realize projects for you with passion,
                            creativity as well as a maximum of reliability and perfection. The design of buildings and
                            rooms, interior fittings and furniture are at the center of our daily work. Whether house
                            building, apartment or business premises - we accompany and advise you from the first
                            consideration to the last execution. We guarantee all planning services and construction
                            supervision. We understand architecture and interior design holistically and thus develop
                            sophisticated concepts. Well-thought-out details are the basis for high utility and
                            sustainability for us. If you have similar wish for a better work environment and job
                            opportunity.
                        </p>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <img src="../../assets/images/dummy/footer.jpg" class="img-fluid" alt="">
                </div>
            </div>
        </div>

    </section>

</template>

<script>
// import BaseCard from './UI/BaseCard';

export default {
    name: 'MeetTheTeam',
    components: {
        // BaseCard
    }
};
</script>

<style scoped>

</style>
