<template>
    <main>
        <div class="container py-5">
            <h4 class="text-uppercase text-danger lt-gp-2 mb-4 text-center" data-aos="fade-up" data-aos-duration="1500">
                CONTACT</h4>
            <div class="row fl-j-c mb-4" data-aos="fade-up" data-aos-duration="1500">
                <div class="col-12 col-lg-2 text-center text-semi-md">
                    <p class="pfirst text-danger text-normal"><i class="fa fa-phone mr-2"></i>79 0222 2522</p>

                </div>
                <div class="col-12 col-lg-4 text-center text-semi-md">
                    <p class="pMid text-danger"><i class="fa fa-envelope-o mr-2"></i>mail@redcarpetinteriors.in</p>

                </div>
                <div class="col-12 col-lg-6 text-center text-semi-md">
                    <p class="plast text-danger"><i class="fa fa-map-marker mr-2"></i>Kothaparambu PO,
                        Kodungallur,
                        Thrissur (DT)
                        680668</p>

                </div>
            </div>
            <div class="row mb-5" data-aos="fade-up" data-aos-duration="1500">
                <div class="col-12 contact-form">
                    <transition name="fade" mode="out-in">
                        <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-if="state === 'S'" key="'S">
                            <div class="text-success">
                                <div class="text-center mb-4">
                                    <i class="fa fa-check-square-o  fa-3x" aria-hidden="true"></i>
                                </div>
                                <div class="text-center">
                                    <p class="fs-4">Thank you <strong>{{ name }}</strong> for Contacting us us
                                    </p>
                                    We have received your request and will respond to you within 24 hours. For
                                    urgent enquiries please call us on the telephone numbers provided.
                                </div>
                            </div>
                        </div>

                        <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'F'"
                             key="'F">
                            <div class="text-danger">
                                <div class="text-center mb-4">
                                    <i class="fa fa-window-close  fa-3x" aria-hidden="true"></i>
                                </div>
                                <div class="text-center">
                                    <p class="fs-4"><strong>OOPS!</strong></p>
                                    Something has gone wrong, Please try again.
                                </div>
                            </div>
                        </div>
                        <div class="text-center p-4 col-12 mt-4 fl-j-c fl-x-c" v-else-if="state === 'L'"
                             key="'L">
                            <div class="text-center p-4">
                                <loading-animation/>
                                <p class="c-font-19 mb-4 proxim-light">Please wait while communicate with
                                    the
                                    server</p>
                            </div>
                        </div>
                        <s-form class="contact-form row" v-else role="form" @submit="onSubmit"
                                autocomplete="off">
                            <div class="col-12 col-lg-6">
                                <validated-input name="Name" v-model="name" shadow="0" placeholder="NAME"
                                                 :rules="rules.first_name" required/>
                                <validated-input type="email" name="Email" v-model="email" shadow="0"
                                                 placeholder="EMAIL"
                                                 :rules="rules.email_id"/>
                                <validated-input type="number" name="Phone" v-model="phone" shadow="0"
                                                 placeholder="PHONE"
                                                 :rules="rules.phone"/>
                            </div>
                            <div class="col-12 col-lg-6">
                                <validated-text-area class="c-textarea" placeholder="MESSAGE" v-model="message"
                                                     :rules="rules.message1"
                                                     shadow="0"/>
                            </div>
                            <div class="col-12 mt-4 fl-x fl-j-c lg-fl-j-e">
                                <button class="lego-btn w-50 bg-primary round-1 px-5 py-4 text-bold" type="submit">
                                    SEND MESSAGE
                                </button>
                            </div>
                        </s-form>
                    </transition>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import axios from 'axios';
import LoadingAnimation from './UI/LoadingAnimation';

export default {
    name: 'contact',
    props: {},
    components: {
        LoadingAnimation
    },
    data () {
        return {
            state: 'D',
            name: '',
            email: '',
            phone: '',
            message: '',
            rules: {
                first_name: {
                    required: true
                },
                phone: {
                    required: true
                },
                email_id: {
                    required: true
                },
                message1: {
                    required: true
                }
            }
        };
    },
    methods: {
        onSubmit () {
            console.log('onSubmit');
            const bodyFormData = new FormData();
            bodyFormData.append('name', this.name);
            bodyFormData.append('email', this.email);
            bodyFormData.append('phone', this.phone);
            bodyFormData.append('message', this.message);

            const that = this;
            this.state = 'L';

            axios({
                method: 'post',
                // url: 'https://redcarpet.xeoscript.com/mail.php',
                url: 'https://redcarpetinteriors.in/mail.php',
                data: bodyFormData,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(function (response) {
                console.log('response', response);
                that.onSubmitSuccess(response);
            }).catch(function (response) {
                that.onSubmitFailed(response);
            });
        },

        onSubmitSuccess () {
            this.state = 'S';
        },

        onSubmitFailed () {
            this.state = 'F';
        }
    }
};
</script>

<style lang="scss" scoped>

.c-textarea {
    &.control {
        min-height: 8rem;
    }
}
</style>
