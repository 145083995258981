<template>
    <div class="col-12 col-md-6 pl-lg-5">
        <header class="row">
            <div class="fl-y fl-a-s mb-2">

                <slot name="header"></slot>
            </div>
        </header>
        <section class="row">
            <slot name="content"></slot>
        </section>
    </div>
</template>
<script>
export default {
    props: {
        heading: {
            type: String
        },
        content: {
            type: String
        },
        icon: {
            type: String
        }
    }
};
</script>
