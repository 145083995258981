<template>
    <main>
        <div class="container py-5">
            <div class="row py-4">
                <div class="col-12">
                    <h4 class="ml-lg-7">Related Projects</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <swiper class="swiper" :options="swiperOption">
                        <swiper-slide>
                            <div class="row">
                                <div class="col-12 col-md-6 singleFolio">
                                    <div class="">
                                        <img src="../../assets/images/folio/q1.jpg" class="slider-img pl-lg-7" alt="">
                                    </div>
                                    <div class="folioHover hover-left py-5 px-5">
                                        <div class="row">
                                            <div class="col-12 text-left">
                                                <div class="text-md font-weight-bold">Project1 Details</div>
                                            </div>
                                            <div class="col-12 text-right">
                                                <div class="fl-x fl-j-s mr-3">
                                                    <span class="mr-3">WEB DESIGN</span> <span>DEVELOPMENT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 singleFolio">
                                    <div class="">
                                        <img src="../../assets/images/folio/q6.jpg" class="slider-img pr-lg-7" alt="">
                                    </div>
                                    <div class="folioHover hover-right py-5 px-5">
                                        <div class="row">
                                            <div class="col-12 text-right">
                                                <div class="text-md font-weight-bold">Project2 Details</div>
                                            </div>
                                            <div class="col-12 text-right">
                                                <div class="fl-x fl-j-e mr-3">
                                                    <span class="mr-3">WEB DESIGN</span> <span>DEVELOPMENT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                        <swiper-slide>
                            <div class="row">
                                <div class="col-12 col-md-6 singleFolio">
                                    <div class="">
                                        <img src="../../assets/images/folio/q2.jpg" class="slider-img pl-lg-7" alt="">
                                    </div>
                                    <div class="folioHover hover-left py-5 px-5">
                                        <div class="row">
                                            <div class="col-12 text-left">
                                                <div class="text-md font-weight-bold">Project3 Details</div>
                                            </div>
                                            <div class="col-12 text-right">
                                                <div class="fl-x fl-j-s mr-3">
                                                    <span class="mr-3">WEB DESIGN</span> <span>DEVELOPMENT</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6 singleFolio">
                                    <div class="fl-x fl-j-c lg-fl-j-s">
                                        <img src="../../assets/images/folio/q3.jpg" class="slider-img pr-lg-7" alt="">
                                    </div>
                                    <div class="folioHover hover-right py-5 px-5">
                                        <div class="row">
                                            <div class="col-12 text-right">
                                                <div class="text-md font-weight-bold">Project4 Details</div>
                                            </div>
                                            <div class="col-12 text-right">
                                                <div class="fl-x fl-j-e mr-3">
                                                    <span class="mr-3">WEB DESIGN</span> <span>DEVELOPMENT</span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </swiper-slide>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'related-projects',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiperOption: {
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        };
    }
};
</script>

<style lang="scss" scoped>
main {
    background: #f2f2f2;
}

//.slider-img {
//    width: 80%;
//    height: auto;
//}
.slider-img {
    width: 100%;
    height: auto;
}

.singleFolio {
    width: 100%;
    height: auto;
    margin-bottom: 0%;
    position: relative;
    z-index: 2;

    .folioHover {
        //box-shadow: 0 8px 8px rgb(0, 0, 0/60%);
        bottom: 0px;
        width: 90%;
        height: 215px;
        position: absolute;
        background: #FFF;
        bottom: 0px;
        padding-left: 30px;
        transition: all ease 400ms;
        -moz-transition: all ease 400ms;
        -webkit-transition: all ease 400ms;
        -ms-transition: all ease 400ms;
        -o-transition: all ease 400ms;
        opacity: 0;
        visibility: hidden;
        z-index: 10;

        &.hover-left {
            left: 0;
        }

        &.hover-right {
            right: 0;
        }
    }

    &:hover {
        .folioHover {
            opacity: 1;
            visibility: visible;
            bottom: 60px;
        }
    }
}

.swiper-button-prev{
    color: var(--color-danger) !important;
}
.swiper-button-next{
    color: var(--color-danger) !important;
}
//.swiper-button-next::after{
//    position: absolute;
//    content: "";
//    background: url("../../assets/images/rlRight.png");
//}

//.folioHover {
//    //box-shadow: 0 8px 8px rgb(0, 0, 0/60%);
//    bottom: 0px;
//    width: 418px;
//    height: 215px;
//    position: absolute;
//    background: #FFF;
//    bottom: 0px;
//    padding-left: 30px;
//    transition: all ease 400ms;
//    -moz-transition: all ease 400ms;
//    -webkit-transition: all ease 400ms;
//    -ms-transition: all ease 400ms;
//    -o-transition: all ease 400ms;
//    opacity: 0;
//    visibility: hidden;
//    z-index: 10;
//}
//
//.folioHover {
//    width: 90%;
//    left: 0;
//}
</style>
