<template>
    <swiper class="swiper" :options="swiperOption">
        <swiper-slide class="slide-1">
            <div class="overlay-dark">
                <h2 class="title text-danger" data-swiper-parallax="-1000">WE DESIGN GREAT</h2>
                <h1 class="subtitle text-4x text-white" data-swiper-parallax="-500" data-aos="fade-up" data-aos-duration="1500">INTERIORS</h1>
            </div>
        </swiper-slide>
        <swiper-slide class="slide-2">
            <div class="overlay-dark">
                <h2 class="title text-danger" data-swiper-parallax="-1000">WE CREATE AWESOME</h2>
                <h1 class="subtitle text-4x text-white" data-swiper-parallax="-500">IDEAS</h1>
            </div>
        </swiper-slide>
        <swiper-slide class="slide-3">
            <div class="overlay-dark">
                <h2 class="title text-danger" data-swiper-parallax="-1000">WE DELIVER TAILORED</h2>
                <h1 class="subtitle text-4x text-white" data-swiper-parallax="-500">DECORS</h1>
            </div>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
    </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'swiper-example-fade-effect',
    title: 'Fade effect',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiperOption: {
                parallax: true,
                loop: true,
                speed: 1500,
                spaceBetween: 30,
                effect: 'fade',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }
        };
    }
};
</script>

<style lang="scss" scoped>

.swiper {
    height: 100vh;

    .swiper-slide {
        background-position: center;
        background-size: cover;;

        &.slide-1 {
            background-image: url('../../assets/images/slider/1.jpg')
        }

        &.slide-2 {
            background-image: url('../../assets/images/slider/2.jpg')
        }

        &.slide-3 {
            background-image: url('../../assets/images/slider/3.jpg')
        }
    }
}

.overlay-dark {
    background: rgba(0, 0, 0, 0.46);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 100px
}
</style>
