<template>
    <section class="py-5">
        <div class="container">
            <div class="row py-4">
                <div class="col-12 col-lg-3 fl-y fl-a-c lg-fl-a-s mb-4 mb-lg-0" data-aos="fade-up" data-aos-duration="1300">
                    <h5 class="text-danger lt-gp-2">
                        OUR APPROACH
                    </h5>
                    <h2 class="text-xl-gl text-left">Design. <br> Develop. <br> Deliver.</h2>
                </div>
                <div class="col-12 col-lg-3 fl-y fl-a-c fl-j-c mb-3 mb-lg-0" data-aos="fade-up" data-aos-duration="1700">

                    <base-counter to="5000" heading="Works done" :count="worksDone"
                                  icon="fa fa-window-maximize fa-3x"></base-counter>

                </div>
                <div class="col-12 col-lg-3 fl-y fl-a-c fl-j-c mb-3 mb-lg-0" data-aos="fade-up" data-aos-duration="1700">

                    <base-counter to="5000" heading="WORKING HOURS" :count="workingHours"
                                  icon="fa fa-clock-o fa-3x"></base-counter>

                </div>
                <div class="col-12 col-lg-3 fl-y fl-a-c fl-j-c mb-3 mb-lg-0" data-aos="fade-up" data-aos-duration="1700">
                    <base-counter to="5000" heading="SATISFIED CLIENTS" :count="satisfiedClients"
                                  icon="fa fa-users fa-3x"></base-counter>

                </div>
            </div>
        </div>
    </section>
</template>
<script>
// import BaseContentCard from '../components/UI/BaseContentCard';
import BaseCounter from '../components/UI/BaseCounter';

export default {
    components: {
        BaseCounter
        // BaseContentCard
    },
    // created () {
    //     console.log('created');
    //     setInterval(function () {
    //         console.log('set interval');
    //         if (this.worksDone < 452) {
    //             this.worksDone++;
    //             console.log(this.worksDone);
    //         }
    //     }, 50);
    // },
    data () {
        return {
            worksDone: 452,
            workingHours: 1250,
            satisfiedClients: 524
        };
    }
};
</script>
