<template>
    <div>
        <InnerBanner/>
        <ProjectsGallery/>
    </div>

</template>
<script>
import ProjectsGallery from './components/ProjectsGallery';
import InnerBanner from '@/views/components/InnerBanner';

export default {
    components: {
        InnerBanner,
        ProjectsGallery
    }
};
</script>
