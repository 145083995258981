<template>
    <div class="home">
        <section id="banner">
            <Banner/>
        </section>
        <section>
            <WelcomeSection/>
        </section>
        <section id="features">
            <WhatWeDo/>
        </section>
        <section>
            <OurApproach/>
        </section>
        <section>
            <related-projects/>
        </section>
        <section>
            <WhyChoose/>
        </section>
        <section id="portfolio">
            <ProjectsGallery/>
        </section>
        <section>
            <work-with-us/>
        </section>
        <section id="team">
            <meet-the-team/>
        </section>
        <section id="contactArea">
            <contact/>
        </section>
        <a href="#" @click="scrollTop" class="scroll-to-top"><i class="fa fa-angle-double-up text-white"></i></a>
    </div>
</template>

<script>

import Banner from '@/views/components/Banner';
import WorkWithUs from '@/views/components/WorkWithUs';
import MeetTheTeam from '@/views/components/MeetTheTeam';
import Contact from '@/views/components/Contact';
import WelcomeSection from '@/views/components/WelcomeSection';
import WhatWeDo from '@/views/components/WhatWeDo';
import OurApproach from '@/views/components/OurApproach';
import RelatedProjects from '@/views/components/RelatedProjects';
import WhyChoose from '@/views/components/WhyChoose';
import ProjectsGallery from '@/views/components/ProjectsGallery';

export default {
    name: 'Home',
    components: {
        ProjectsGallery,
        WhyChoose,
        OurApproach,
        WhatWeDo,
        WelcomeSection,
        Contact,
        MeetTheTeam,
        WorkWithUs,
        Banner,
        RelatedProjects
    },
    mounted () {
        function setActiveLink (linkEl) {
            const headerLinksEls = document.getElementsByClassName('header-link');
            console.log('header links', headerLinksEls);
            Array.from(headerLinksEls).forEach(function (linkEl) {
                linkEl.classList.remove('active');
            });
            // Add active class
            linkEl.classList.add('active');
        }

        const headerLinksEls = document.getElementsByClassName('header-link');
        Array.from(headerLinksEls).forEach(function (linkEl) {
            if (linkEl.classList.contains('display-none')) {
                linkEl.classList.remove('display-none');
            }
        });

        // Home Link
        const homeLinkEl = document.querySelector('.home-link');
        homeLinkEl.addEventListener('click', function (e) {
            e.preventDefault();
            window.scrollTo(0, 0);
            // Change active class
            setActiveLink(homeLinkEl);
        });
        // Feature link
        const freatureLinkEl = document.querySelector('.feature-link');
        // ADD CLICK EVENT FOR SCROLLING
        freatureLinkEl.addEventListener('click', function (e) {
            e.preventDefault();
            const featuresEl = document.querySelector('#features');
            window.scrollTo(500, featuresEl.offsetTop - 50);
            // Change active class
            setActiveLink(freatureLinkEl);
        });
        // PORTFOLIO LINK
        const portfolioLinkEl = document.querySelector('.portfolio-link');
        // ADD CLICK EVENT FOR SCROLLING
        portfolioLinkEl.addEventListener('click', function (e) {
            e.preventDefault();
            console.log('portfolio-link', portfolioLinkEl);
            const portfolioEl = document.querySelector('#portfolio');
            window.scrollTo(500, portfolioEl.offsetTop - 100);
            setActiveLink(portfolioLinkEl);
        });
        // TEAMS LINK
        // const teamLinkEl = document.querySelector('.team-link');
        // // ADD CLICK EVENT FOR SCROLLING
        // teamLinkEl.addEventListener('click', function (e) {
        //     e.preventDefault();
        //     const teamEl = document.querySelector('#team');
        //     window.scrollTo(500, teamEl.offsetTop - 100);
        //     setActiveLink(teamLinkEl);
        // });
        // CONTACT LINK
        const contactLinkEl = document.querySelector('.contact-link');
        console.log('contact-link-el', contactLinkEl);
        // ADD CLICK EVENT FOR SCROLLING
        contactLinkEl.addEventListener('click', function (e) {
            e.preventDefault();
            const contactEl = document.querySelector('#contactArea');
            console.log('contactEl', contactEl);
            window.scrollTo(500, contactEl.offsetTop - 100);
            setActiveLink(contactLinkEl);
        });

        // Get quote btn
        const getQuoteBtnEl = document.querySelector('.get-quote-btn');
        console.log('contact-link-el', contactLinkEl);
        // ADD CLICK EVENT FOR SCROLLING
        getQuoteBtnEl.addEventListener('click', function (e) {
            e.preventDefault();
            const contactEl = document.querySelector('#contactArea');
            console.log('contactEl', contactEl);
            window.scrollTo(500, contactEl.offsetTop - 100);
            // setActiveLink(contactLinkEl);
        });
    },
    methods:
{
    scrollTop () {
        window.scrollTo(0, 0);
    }
}
}
;

</script>
<style scoped>

</style>
