<template>
    <section class="py-5">
        <div class="container">
            <div class="row py-4 px-0 text-center">
                <div class="col-12 col-xl-6" data-aos="fade-up" data-aos-duration="1300">
                    <img src="../../assets/images/dummy/side.jpg" class="img-fluid" alt="">
                </div>
                <div class="col-12 col-xl-6 pt-5">
                    <div class="row">
                        <div class="col-12 fl-y fl-a-s" data-aos="fade-up" data-aos-duration="1500">
                            <h5 class="text-danger lt-gp-2">
                                WHAT WE DO
                            </h5>
                            <h2 class="text-xl text-left">Surprising Quality for the Designs of the Future</h2>
                            <p class="text-left text-semi-md text-bold text-blue font-weight-normal">
                                Our team will establish a solid foundation for a living environment in your image.
                                Maximize the space you have!
                            </p>
                        </div>
                    </div>
                    <div class="row px-2">
                        <base-content-card data-aos="fade-up" data-aos-duration="1500">
                            <template #header>
                                <i class="fa fa-book fa-3x mb-2 text-danger"></i>
                                <h5>Trends And Styles</h5>
                            </template>
                            <template #content>
                                <p class="text-left">
                                    The development of a unique and personalized concept makes it possible to create the
                                    desired atmosphere and
                                    select the right materials to use. Start your work on the right foot and avoid
                                    unpleasant surprises.
                                </p>
                            </template>
                        </base-content-card>
                        <base-content-card data-aos="fade-up" data-aos-duration="1500">
                            <template #header>
                                <i class="fa fa-life-ring fa-3x mb-2 text-danger"></i>
                                <h5>Design & Development</h5>
                            </template>
                            <template #content>
                                <p class="text-left">
                                    The development of a unique and personalized concept makes it possible
                                    to create the desired atmosphere and select the right materials to use.
                                    Start your work on the right foot and avoid unpleasant surprises.
                                </p>
                            </template>
                        </base-content-card>
                        <base-content-card data-aos="fade-up" data-aos-duration="1500">
                            <template #header>
                                <i class="fa fa-area-chart fa-3x mb-2 text-danger"></i>
                                <h5>Architecture & Decors</h5>
                            </template>
                            <template #content>
                                <p class="text-left">
                                    Interior designer, decorator but also colorist designer thanks to our last year of
                                    study,
                                    so many caps that allow me to carry out the various projects of development and
                                    rehabilitation of apartments or houses.
                                    We offer clients personalized advice tailored to their desires, budgets and tastes.
                                </p>
                            </template>
                        </base-content-card>
                        <base-content-card data-aos="fade-up" data-aos-duration="1500">
                            <template #header>
                                <i class="fa fa-file-text fa-3x mb-2 text-danger"></i>
                                <h5>Lifestyle Ambience</h5>
                            </template>
                            <template #content>
                                <p class="text-left">
                                    We work since the beginning of our activity in close collaboration with general
                                    companies all bodies of state.
                                    They take care of the implementation of all our projects. They are also responsible
                                    for providing you with a precise and detailed estimate of their intervention.
                                </p>
                            </template>
                        </base-content-card>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import BaseContentCard from '../components/UI/BaseContentCard';

export default {
    components: {
        BaseContentCard
    }
};
</script>
<style scoped>
.text-blue{
    color: #336eab;
}
</style>
